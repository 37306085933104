<template>
    <div class="custom">
        <div class="title">
            {{ $t('lastMessages.range') }}
        </div>
        <div class="interval">
            <span class="date" :class="{ 'ruFormat': firstDayOfWeek !== 0 }" @click="click1">
                <i class="icon"></i>
                <input v-show="firstDayOfWeek == 0" id="startDate" ref="calendarFirst" type="date" autocomplete="off"
                    v-model="firstDate">
                <span v-if="firstDate && firstDayOfWeek !== 0" class="normalFormat"> {{ ruDateFirst }}
                </span>
            </span>

            <span v-if="firstDate !== ''" class="separator"></span>

            <span v-show="firstDate !== ''" class="date" :class="{ 'ruFormat': firstDayOfWeek !== 0 }" @click="click2">
                <i class="icon"></i>
                <input v-show="firstDayOfWeek == 0" id="endDate" ref="calendarLast" type="date" autocomplete="off"
                    v-model="lastDate">
                <span v-if="firstDate && firstDayOfWeek !== 0" class="normalFormat"> {{ ruDateLast }}
                </span>
            </span>
        </div>
    </div>
</template>
<script>
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import { English } from "flatpickr/dist/l10n/default.js"
import { Russian } from "flatpickr/dist/l10n/ru.js";

const currentDate = new Date();
const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 600, currentDate.getDate());

import { mapState } from 'vuex';

export default {
    data: () => ({
        firstDate: '',
        lastDate: '',
        firstDayOfWeek: 0,
        dateFormat: "m.d.Y",
        language: English,
    }),

    mounted() {
        const lang = localStorage.getItem('lang')
        if (lang == 'ru') {
            this.language = Russian
        } else {
            this.language = English
        }

        if (this.$store?.state?.newCalendarFormat == ''
            || this.$store?.state?.newCalendarFormat == 'Auto') {
            this.Auto()
        }

        if (this.$store?.state?.newCalendarFormat == 'SUN') {
            this.firstDayOfWeek = 0
        }

        if (this.$store?.state?.newCalendarFormat == 'MON') {
            this.firstDayOfWeek = 1
        }

        if (this.$store?.state?.newCalendarFormat == 'SAT') {
            this.firstDayOfWeek = 6
        }

        flatpickr(this.$refs.calendarFirst, {
            dateFormat: this.dateFormat,
            locale: {
                ...this.language,
                firstDayOfWeek: this.firstDayOfWeek
            },
            appendTo: document.querySelector('.interval'),
            maxDate: "today",
            minDate: minDate,
            disableMobile: "true"
        })

        this.$emit('filteredDateChanged', this.formattedDate)
    },

    watch: {
        formattedDate(newDate) {
            this.$emit('filteredDateChanged', newDate)
        },

        firstDate(value) {
            const firstDate = new Date(value);
            const today = new Date();
            let threeMonthsForward = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, firstDate.getDate());

            if (threeMonthsForward > today) {
                threeMonthsForward = today;
            }

            flatpickr(this.$refs.calendarLast, {
                dateFormat: this.dateFormat,
                locale: {
                    ...this.language,
                    firstDayOfWeek: this.firstDayOfWeek
                },
                appendTo: document.querySelector('.interval'),
                maxDate: this.lastDate == "" ? 'today' : threeMonthsForward,
                minDate: this.firstDate,
                disableMobile: "true"
            })

            const day = threeMonthsForward.getDate()
            const month = threeMonthsForward.getMonth() + 1
            const year = threeMonthsForward.getFullYear();

            const formattedLastDate = `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}.${year}`

            this.lastDate = formattedLastDate
        },

        lastDate() {
            if (this.lastDate == "") {
                this.lastDate = ""
            }
        }

    },

    computed: {
        ...mapState(['newCalendarFormat']),

        formattedDate() {
            const firstDay = this.firstDate;
            const lastDay = this.lastDate;
            let formattedFirstDay = this.formatDate(firstDay);
            let formattedLastDay = this.formatDate(lastDay);

            if (formattedFirstDay === formattedLastDay) {
                return formattedFirstDay + ' - ' + formattedLastDay;
            } else {
                return formattedFirstDay + ' - ' + formattedLastDay;
            }
        },

        ruDateFirst() {
            const ruDatefirst = this.formattedDate.split('-')[0]

            const parts = ruDatefirst.split(' ');
            const day = parts[0].padStart(2, '0');
            const month = parts[1];
            const year = parts[2];

            const formattedRuDateFirst = `${day}.${this.getMonthNumber(month)}.${year}`;


            return formattedRuDateFirst


        },

        ruDateLast() {
            const ruDateLast = this.formattedDate?.split('- ')[1] ?? this.formattedDate?.split('-')?.[0]
            const parts = ruDateLast.split(' ');
            const day = parts[0].padStart(2, '0');
            const month = parts[1];
            const year = parts[2];

            const formattedRuDateLast = `${day}.${this.getMonthNumber(month)}.${year}`;


            return formattedRuDateLast
        }
    },

    methods: {
        Auto() {
            const date = new Date();
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);

            if (formattedDate.includes('/')) {
                this.firstDayOfWeek = 0; // Формат США
            } else if (formattedDate.includes('.')) {
                this.firstDayOfWeek =  1; // Формат Европы
            } else if (formattedDate.includes('-')) {
                this.firstDayOfWeek = 6; // Формат ISO
            } else {
                this.firstDayOfWeek = 1
            }
        },

        getMonthNumber(month) {
            const months = [
                'JAN',
                'FEB',
                'MAR',
                'APR',
                'MAY',
                'JUN',
                'JUL',
                'AUG',
                'SEP',
                'OCT',
                'NOV',
                'DEC'
            ]

            const monthIndex = months.indexOf(month)
            const monthNumber = monthIndex + 1

            return monthNumber.toString().padStart(2, '0')
        },

        formatDate(intervalDate) {
            const dateStr = intervalDate
            const parts = dateStr.split('.');
            let day = parseInt(parts[1], 10)
            let month = parseInt(parts[0], 10) - 1
            const year = parseInt(parts[2], 10);

            const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

            const date = new Date(year, month, day);
            const formattedDate = `${date.getDate()} ${months[date.getMonth()]} ${year}`;
            return formattedDate
        },

        click1() {
            const child = document.querySelector('#startDate')
            child.click()
        },

        click2() {
            const child = document.querySelector('#endDate')
            child.click()
        }
    }


}
</script>
<style scoped lang="scss">
.custom {
    margin-top: 40px;
}

.title {
    color: #FFF;
    margin-left: 27px;
    margin-bottom: 24px;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.interval {
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.date {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 41px;
    padding: 13px 27px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
    cursor: pointer;
}

.ruFormat {
    width: 90px;
    justify-content: flex-start;
}

.icon {
    width: 16px;
    height: 18px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20' fill='none'%3E%3Cpath d='M1 7H17M1 7V15.8002C1 16.9203 1 17.4801 1.21799 17.9079C1.40973 18.2842 1.71547 18.5905 2.0918 18.7822C2.5192 19 3.07899 19 4.19691 19H13.8031C14.921 19 15.48 19 15.9074 18.7822C16.2837 18.5905 16.5905 18.2842 16.7822 17.9079C17 17.4805 17 16.9215 17 15.8036V7M1 7V6.2002C1 5.08009 1 4.51962 1.21799 4.0918C1.40973 3.71547 1.71547 3.40973 2.0918 3.21799C2.51962 3 3.08009 3 4.2002 3H5M17 7V6.19691C17 5.07899 17 4.5192 16.7822 4.0918C16.5905 3.71547 16.2837 3.40973 15.9074 3.21799C15.4796 3 14.9203 3 13.8002 3H13M5 3H13M5 3V1M13 3V1M13 11H5' stroke='url(%23paint0_linear_1836_15176)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1836_15176' x1='1.16185' y1='11.3328' x2='14.1561' y2='11.3328' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.normalFormat {
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 81.4px;
}

.separator {
    margin-left: 14px;
    margin-right: 14px;
    width: 24px;
    height: 1px;
    border-radius: 4px;
    background-color: #A2A2A2;
}

@media (max-width:680px) {
    .date {
        padding: 13px 20px;
    }

    .separator {
        width: 14px;
        margin-left: 7px;
        margin-right: 7px;
    }

    .icon {
        margin-right: 10px;
    }
}
</style>