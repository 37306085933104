<template>
    <div class="result-message">
        {{ $t('lastMessages.found') }} {{ filteredMessages.length }} {{ $t('lastMessages.donationsAmount') }} {{ getSumUSD.toFixed(2) }} {{ fiat }}
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: ['filteredMessages'],

    data: () => ({
        fiat: 'USD',
    }),

    created() {
        this.$watch('newFiat', (newValue) => {
            this.fiat = newValue
        });
    },

    mounted() {
        this.fiat = this.$store?.state?.newFiat == '' ? 'USD' : this.$store?.state?.newFiat
    },

    computed: {
        ...mapState(['newFiat']),

        getSumUSD() {
            if (this.fiat == 'USD' || this.fiat == '')
                return this.$props.filteredMessages?.slice(0, 50)?.map(item => item?.fiatValue?.usd).reduce((acc, n) => {
                    return acc += n
                }, 0)

            if (this.fiat == 'EUR') {
                return this.$props.filteredMessages?.slice(0, 50)?.map(item => item?.fiatValue?.eur).reduce((acc, n) => {
                    return acc += n
                }, 0)
            }

            if (this.fiat == 'RUB') {
                return this.$props.filteredMessages?.slice(0, 50)?.map(item => item?.fiatValue?.rub).reduce((acc, n) => {
                    return acc += n
                }, 0)
            }
            return this.getSumUSD
        },
    }
}
</script>
<style scoped lang="scss">
.result-message {
    margin-left: 17px;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

@media (min-width: 0px) and (max-width: 1290px) {
    .result-message {
        font-weight: 400;
    }
}

@media (max-width: 680px) {
    .result-message {
        margin-left: 13px;
    }
}
</style>
