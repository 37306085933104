<template>
    <ul class="table">
        <li v-if="!currentDate.includes('NaN')" class="titles">
            <span class="title title1">{{ $t('lastMessages.username') }}</span>
            <span class="title title2">{{ $t('lastMessages.amount') }}</span>
            <span class="title title3">{{ $t('lastMessages.currency') }}</span>
            <span class="title title4">{{ $t('lastMessages.message') }}</span>
            <span class="title title5">{{ $t('lastMessages.network') }}</span>
            <span class="title title6">{{ $t('lastMessages.date') }}
            </span>
        </li>
        <Message v-for="message in filteredItems.slice(0, 50)" :key="message.id" :message="message" />

    </ul>
</template>
<script>
import Message from '@/components/lastmessages/message.vue';
import i18n from '../../i18n';

export default {
    components: { Message },

    props: {
        currentDate: String,
        curentPeriodId: Number,
        search: String,
        filteredMessages: {
            type: Array,
            required: true
        },
    },

    created() {
        this.Start();
    },

    mounted() {
        this.$emit('filteredMessagesChanged', this.filteredItems);
    },

    watch: {
        "$firebase.account"() {
            this.Start();
        },
        "$donatrix.settingsReady"() {
            this.Start();
        },

        filteredItems(filteredItems) {
            this.$emit('filteredMessagesChanged', filteredItems);
        },

    },

    computed: {
        filteredItems() {
            let filteredPeriods = this.$messages.messages;

            if (this.search) {
                filteredPeriods = filteredPeriods.filter(message =>
                    message.nickname.toLowerCase().startsWith(this.search.toLowerCase())
                );
            }

            if (this.currentDate) {
                const options = { day: 'numeric', month: 'short', year: 'numeric' };

                if (this.curentPeriodId == 1) {
                    filteredPeriods = filteredPeriods.filter(message =>
                        message.confirmedAt.toLocaleDateString(i18n.t('lastMessages.dateFormat'), options).toUpperCase() === this.currentDate
                    );
                }

                if ((this.curentPeriodId == 2)) {
                    let [startDate, endDate] = this.currentDate.split('-');

                    filteredPeriods = filteredPeriods.filter(message => {
                        const messageDate = Date.parse(message.confirmedAt.toLocaleDateString('en-GB', options).toUpperCase());


                        if (localStorage.getItem('lang') == 'ru') {

                            const trimmedStartDate = startDate?.trim();
                            const trimmedEndDate = endDate?.trim();
                            const months = {
                                "ЯНВ.": "01",
                                "ФЕВ.": "02",
                                "МАРT": "03",
                                "АПР.": "04",
                                "МАЙ": "05",
                                "ИЮНЬ": "06",
                                "ИЮЛЬ": "07",
                                "АВГ.": "08",
                                "СЕНТ.": "09",
                                "ОКТ.": "10",
                                "НОЯБ.": "11",
                                "ДЕК.": "12"
                            };
                            const monthKey1 = trimmedStartDate?.split(' ')[1];
                            const year1 = trimmedStartDate?.split(' ')[2];
                            const month1 = months[monthKey1];
                            const day1 = trimmedStartDate?.split(' ')[0];
                            const monthKey2 = trimmedEndDate?.split(' ')[1]
                            const year2 = trimmedEndDate?.split(' ')[2]
                            const month2 = months[monthKey2];
                            const day2 = trimmedEndDate?.split(' ')[0];
                            const formattedStartDate = `${year1}-${month1}-${day1}`;
                            const formattedEndDate = `${year2}-${month2}-${day2}`;
                            const parsedStartDate = Date.parse(formattedStartDate);
                            const parsedEndDate = Date.parse(formattedEndDate);
                            const messageDateRu = Date.parse(message.confirmedAt)
                            const parsedEndDateWithTime = parsedEndDate + 24 * 60 * 60 * 1000;

                            if (parsedStartDate === parsedEndDate) {
                                return messageDateRu >= parsedStartDate && messageDateRu <= parsedEndDateWithTime;
                            } else {
                                return messageDateRu >= parsedStartDate && messageDateRu <= parsedEndDateWithTime
                            }

                        } else {

                            return messageDate >= Date.parse(startDate) && messageDate <= Date.parse(endDate);
                        }
                    });

                }

                if (this.curentPeriodId == 3) {
                    const options = { month: 'short', year: 'numeric' };
                    filteredPeriods = filteredPeriods.filter(message =>
                        message.confirmedAt.toLocaleDateString(i18n.t('lastMessages.dateFormat'), options).toUpperCase() === this.currentDate
                    );
                }

            }

            if (this.curentPeriodId == 5) {
                const options = { day: 'numeric', month: 'short', year: 'numeric' };
                const [startDate, endDate] = this.currentDate.split('-');

                filteredPeriods = filteredPeriods.filter(message => {
                    const messageDate = Date.parse(message.confirmedAt.toLocaleDateString('en-GB', options));
                    return messageDate >= Date.parse(startDate) && messageDate <= Date.parse(endDate);
                });
            }

            return filteredPeriods;
        }
    },

    methods: {
        Start() {
            if (!this.$donatrix.settingsReady || !this.$firebase.account) return;
            this.$messages.StartListen();
        },

    },
}
</script>
<style scoped lang="scss">
.table {
    width: 100%;
    margin-top: 16px;
    height: 100vh;
    overflow-y: scroll;
}

.table::-webkit-scrollbar {
    width: 9px;
    background-color: transparent;
}

.table::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.20);
}

.titles {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(7, 1fr);
    gap: 0px 10px;
    padding: 16px 25px;
    margin-bottom: 24px;
    border-radius: 7.692px;
    background: rgba(13, 11, 23, 0.6);
}

.title {
    display: grid;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.title1 {
    grid-column: 1;
}

.title2 {
    grid-column: 2;
}

.title3 {
    grid-column: 3;
}

.title4 {
    grid-column-start: 4;
    grid-column-end: 6;
}

.title5 {
    grid-column-start: 6;
}

.title6 {
    grid-column-start: 7;
}



@media (min-width:1800px) {
    .titles {
        padding: 25px;
    }

    .title {
        font-size: 16px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .titles {
        display: none;
    }
}
</style>