<template>
    <span class="filter">
        <i class="filter-arrow prev" @click="prevMonth"></i>
        {{ formattedDate }}
        <i class="filter-arrow next" @click="nextMonth"></i>
    </span>
</template>
<script>
import i18n from '../../i18n';
export default {
    data: () => ({
        currentDate: new Date()
    }),

    mounted() {
        this.$emit('filteredDateChanged', this.formattedDate)
    },

    watch: {
        formattedDate(newDate) {
            this.$emit('filteredDateChanged', newDate)
        }
    },

    computed: {
        formattedDate() {
            const threeMonthsAgo = this.getThreeMonthsAgoDate();
            const nextMonth = this.getNextMonthDate();

            if (this.currentDate <= threeMonthsAgo) {
                return this.formatMonth(threeMonthsAgo);
            } else if (this.currentDate >= nextMonth) {
                return this.formatMonth(new Date());
            } else {
                return this.formatMonth(this.currentDate);
            }
        }
    },

    methods: {
        formatMonth(date) {
            const month = date.toLocaleString(i18n.t('lastMessages.dateFormat'), { month: 'short', year: 'numeric' }).toUpperCase();
            return month;
        },

        getThreeMonthsAgoDate() {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 12);
            return currentDate;
        },

        getNextMonthDate() {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 1);
            return currentDate;
        },

        prevMonth() {
            const previousMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
            if (previousMonth >= this.getThreeMonthsAgoDate()) {
                this.currentDate = previousMonth;
            }
        },

        nextMonth() {
            const nextMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
            if (nextMonth <= new Date()) {
                this.currentDate = nextMonth;
            }
        }
    }
}
</script>
<style scoped lang="scss">
.filter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 36px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 41px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
}

.filter-arrow {
    width: 10px;
    height: 11px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.prev {
    margin-right: 18px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7 11L1 6L7 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.next {
    margin-left: 18px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M1 1L6 6L1 11' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px)  {
    .filter {
        height: 41px;
    }
}

@media (max-width: 680px) {
    .filter {
        width: 170px;
        height: 37px;
    }
}
</style>