<template>
    <li class="row">
        <span class="title title-name">{{ $t('lastMessages.username') }}:</span>
        <span class="name">{{ message.nickname }}</span>

        <span class="title title-amount">{{ $t('lastMessages.amount') }}:</span>
        <span class="amount">{{ message?.nativeValue }}</span>

        <span class="title title-currency">{{ $t('lastMessages.currency') }}:</span>
        <span class="currency">{{ message?.ticker }}</span>

        <span class="title title-message">{{ $t('lastMessages.message') }}:</span>
        <span class="message">{{ message?.text }}</span>

        <span class="title title-network">{{ $t('lastMessages.network') }}:</span>
        <span class="network">{{ message?.blockchain }}</span>

        <span class="title title-date">{{ $t('lastMessages.date') }}:</span>
        <span class="date">{{ message.date }}
            <a class="link" v-if="message?.blockExplorerUrl" :href="message?.blockExplorerUrl" target="_blank"></a>
            <i class="delete" @click="remove" v-if="!$firebase.readOnly"></i>
        </span>
    </li>
</template>
<script>
import { Message } from "../../code/classes/Message";
export default {
    props: {
        message: { type: Message, default: undefined },
    },

    computed: {
        classes() {
            const classes = [];
            if (this.message.isRemoving) classes.push("removing");
            if (this.message.status === "Fake") classes.push("fake");
            return classes.join(" ");
        },

        coinAmount() {
            return this?.message?.uiValue?.split('/')?.[0] ?? ''
        },

    },

    methods: {
        remove() {
            const id = this.message?.id;
            if (!id) return;

            this.$messages.removeInboxItem(id, () => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        const answer = window.confirm(`Are you sure to remove '${this.message.text}'?`);
                        resolve(answer);
                    }, 100);
                });
            });
        },

        replay() {
            const id = this.message.id;
            this.$messages.replayInboxItem(id);
        },
    },
}
</script>
<style scoped lang="scss">
.row {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(7, 1fr);
    gap: 0px 5px;
    padding: 16px 24px 16px 27px;
    background: rgba(7, 7, 7, 0.15);
}

.title {
    display: none;
}

.row:not(:last-child) {
    margin-bottom: 16px;
}

.name,
.amount,
.currency,
.message,
.network,
.date {
    display: flex;
    align-items: flex-start;
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.name{
    grid-column: 1;
}

.currency {
    grid-column: 3;
}

.amount {
    background: var(--0, linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    grid-column: 2;
}

.network {
    grid-column: 6;
}

.date {
    color: #A2A2A2;
    justify-content: space-between;
    grid-column: 7;
}

.message {
    max-width: 90%;
    overflow-wrap: anywhere;
    grid-column-start: 4;
    grid-column-end: 6;
}

.date {
    display: flex;
    align-items: flex-start;
}

.link {
    width: 15.07px;
    height: 15.071px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M5.43565 9.56464L9.56465 5.43589M3.88756 6.98401L2.85531 8.01619C1.71512 9.15632 1.71479 11.005 2.85498 12.1451C3.99517 13.2852 5.84433 13.2849 6.98452 12.1447L8.01567 11.1127M6.98386 3.88718L8.01611 2.85499C9.1563 1.71487 11.0047 1.71507 12.1449 2.85519C13.2851 3.99531 13.285 5.84383 12.1448 6.98396L11.1131 8.01611' stroke='%23A2A2A2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.delete {
    width: 11px;
    height: 11.321px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M7.875 5.32732V9.72984M5.125 5.32732V9.72984M2.375 2.8116V10.233C2.375 10.9374 2.375 11.2894 2.52487 11.5585C2.65669 11.7952 2.86689 11.988 3.12561 12.1086C3.41945 12.2456 3.80431 12.2456 4.57288 12.2456H8.42712C9.19569 12.2456 9.57999 12.2456 9.87383 12.1086C10.1326 11.988 10.3435 11.7952 10.4753 11.5585C10.625 11.2897 10.625 10.938 10.625 10.2349V2.8116M2.375 2.8116H3.75M2.375 2.8116H1M3.75 2.8116H9.25M3.75 2.8116C3.75 2.22551 3.75 1.93261 3.85467 1.70145C3.99422 1.39324 4.26172 1.14822 4.59863 1.02055C4.85132 0.924805 5.17183 0.924805 5.8125 0.924805H7.1875C7.82817 0.924805 8.1485 0.924805 8.40119 1.02055C8.7381 1.14822 9.00571 1.39324 9.14526 1.70145C9.24993 1.93261 9.25 2.22551 9.25 2.8116M9.25 2.8116H10.625M10.625 2.8116H12' stroke='%23A2A2A2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {

    .name,
    .amount,
    .currency,
    .message,
    .network,
    .date {
        font-size: 16px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .row {
        display: grid;
        position: relative;
        padding: 14px 24px 0px 30px;
        grid-template-columns: repeat(2, 18%);
        border-radius: 7.692px;
        background: radial-gradient(123.76% 146.58% at 0% 0%, rgba(234, 150, 255, 0.12) 0%, rgba(130, 104, 210, 0.29) 100%);

    }

    .row span,
    .title {
        margin-bottom: 14px;
    }

    .title {
        display: flex;
        background: #A2A2A2;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 12px */
    }

    .title-name {
        grid-column-start: 1;
        grid-row-start: 1;
    }

    .name {
        grid-column-start: 2;
        grid-row-start: 1;
    }

    .title-date {
        grid-column-start: 1;
        grid-row-start: 2;
    }

    .date {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    .title-amount {
        grid-column-start: 1;
        grid-row-start: 3;
    }

    .amount {
        grid-column-start: 2;
        grid-row-start: 3;
    }

    .title-currency {
        grid-column-start: 1;
        grid-row-start: 4;
    }

    .currency {
        grid-column-start: 2;
        grid-row-start: 4;
    }

    .title-network {
        grid-column-start: 1;
        grid-row-start: 5;
    }

    .network {
        grid-column-start: 2;
        grid-row-start: 5;
    }

    .title-message {
        grid-column-start: 1;
        grid-row-start: 6;
    }

    .message {
        max-width: 90%;
        grid-column-start: 2;
        grid-row-start: 6;
    }

    .link {
        position: absolute;
        margin: 0;
        bottom: 13px;
        right: 55px;
    }

    .delete {
        position: absolute;
        right: 23px;
        bottom: 15px;
    }
}

@media (max-width: 680px) {
    .row {
        padding: 38px 13px 0px 13px;
        grid-template-columns: repeat(2, 40%);
    }

    .link {
        right: 39px;
        top: 15px;
    }

    .delete {
        top: 16px;
        right: 7px;
    }
}
</style>