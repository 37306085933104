<template>
    <span class="filter">
        <i class="filter-arrow prev" @click="changeWeek(-1)"></i>
        {{ formattedDate }}
        <i class="filter-arrow next" @click="changeWeek(1)"></i>
    </span>
</template>
<script>
import i18n from '../../i18n';
export default {
    data: () => ({
        currentDate: new Date(),
        firstDayOfWeek: 0,

    }),

    mounted() {
        this.$emit('filteredDateChanged', this.formattedDate)

        if (this.$store?.state?.newCalendarFormat == ''
            || this.$store?.state?.newCalendarFormat == 'Auto') {
            this.Auto()
        }

        if (this.$store?.state?.newCalendarFormat == 'SUN') {
            this.firstDayOfWeek = 0
        }

        if (this.$store?.state?.newCalendarFormat == 'MON') {
            this.firstDayOfWeek = 1
        }

        if (this.$store?.state?.newCalendarFormat == 'SAT') {
            this.firstDayOfWeek = 6
        }
    },

    watch: {
        formattedDate(newDate) {
            this.$emit('filteredDateChanged', newDate)
        }
    },

    computed: {
        formattedDate() {
            const firstDayOfWeek = this.getStartDateOfWeek(this.currentDate);
            const lastDayOfWeek = this.getEndDateOfWeek(this.currentDate);
            const threeMonthsAgo = this.getThreeMonthsAgoDate();

            let formattedFirstDay = this.formatDate(firstDayOfWeek);
            let formattedLastDay = (lastDayOfWeek <= new Date() && lastDayOfWeek >= threeMonthsAgo)
                ? this.formatDate(lastDayOfWeek)
                : this.formatDate(new Date());

            if (formattedFirstDay === formattedLastDay) {
                return formattedFirstDay + ' - ' + formattedLastDay;
            } else {
                return formattedFirstDay + ' - ' + formattedLastDay;
            }

        },
    },

    methods: {
        Auto() {
            const date = new Date();
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);

            if (formattedDate.includes('/')) {
                this.firstDayOfWeek = 0; // Формат США
            } else if (formattedDate.includes('.')) {
                this.firstDayOfWeek = 1; // Формат Европы
            } else if (formattedDate.includes('-')) {
                this.firstDayOfWeek = 6; // Формат ISO
            } else {
                this.firstDayOfWeek = 1
            }
        },


        getStartDateOfWeek(date) {

            if (this.firstDayOfWeek == 0) {
                const firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
                return firstDay;
            }

            if (this.firstDayOfWeek == 1) {
                const dayOfWeek = date.getDay();
                const firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek + (dayOfWeek == 0 ? -6 : 1));
                return firstDay
            }

            if (this.firstDayOfWeek == 6) {
                const dayOfWeek = date.getDay();
                const firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek - 1);
                return firstDay;
            }
        },

        getEndDateOfWeek(date) {
            if (this.firstDayOfWeek == 0) {
                const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - date.getDay()));
                return lastDay;
            }

            if (this.firstDayOfWeek == 1) {
                const dayOfWeek = date.getDay();
                const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek - (dayOfWeek - 8));
                return lastDay
            }

            if (this.firstDayOfWeek == 6) {
                const dayOfWeek = date.getDay();
                const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek - (dayOfWeek - 6));
                return lastDay
            }


        },

        getThreeMonthsAgoDate() {
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 11);
            return threeMonthsAgo;
        },

        formatDate(date) {
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString(i18n.t('lastMessages.dateFormat'), options).toUpperCase();
        },

        changeWeek(amount) {
            const newDate = new Date(this.currentDate.getTime() + (7 * 24 * 60 * 60 * 1000 * amount));
            const today = new Date();
            const threeMonthsAgo = this.getThreeMonthsAgoDate();
            if (newDate <= today && newDate >= threeMonthsAgo) {
                this.currentDate = newDate;
            }
        },
    }
};
</script>
<style scoped lang="scss">
.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 256px;
    height: 36px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 41px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
}

.filter-arrow {
    width: 10px;
    height: 11px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.prev {
    margin-left: 21px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7 11L1 6L7 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.next {
    margin-right: 21px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M1 1L6 6L1 11' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {
    .filter {
        height: 41px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .filter {
        margin-top: 24px;
    }
}

@media (max-width: 680px) {
    .filter {
        margin-top: 0;
        height: 37px;
    }

    .prev {
        margin-left: 10px;
    }

    .next {
        margin-right: 10px;
    }
}
</style>