<template>
    <div class="main">
        <div class="messages-title">{{ $t('lastMessages.title') }}</div>
        <div class="messages-top">
            <ul class="messages-top__left">
                <li class="messages-top__left-item">
                    <input v-model="search" class="search" type="text" :placeholder="placeholder">
                    <i class="search-icon"> </i>
                </li>
                <li class="messages-top__left-item">
                    <span class="period" @click="openPeriods"> {{ choosenPeriod }}
                        <i class="arrow"></i>
                        <Periods v-if="openPeriod" @periodChanged="updatePeriod" @changedPeriodId="updatePeriodId"
                            @cleanSearch="updateSearch" />
                    </span>
                </li>
                <li class="messages-top__left-item">
                    <DayFilter v-if="choosenPeriodId === 1" @filteredDateChanged="updateFilteredDate" />
                    <WeekFilter v-if="choosenPeriodId === 2" @filteredDateChanged="updateFilteredDate" />
                    <MonthFilter v-if="choosenPeriodId === 3" @filteredDateChanged="updateFilteredDate" />
                </li>
            </ul>
            <button class="messages-top__right btn" @click="newMessage">
                <i class="button-icon"></i>
               <span>{{ $t('buttons.AddDonation') }}</span>
            </button>

            <NewMessage ref="child" :value="newMessageIsOpen" @update-value="newMessageIsOpen = $event"
                v-if="newMessageIsOpen" />
        </div>
        <CustomInterval v-if="choosenPeriodId == 5" @filteredDateChanged="updateFilteredDate" />

        <SearchResult v-if="!currentDate.includes('NaN')" :filteredMessages="filteredMessages" />

        <Table :search="search" :current-date="currentDate" :curent-period-id="choosenPeriodId"
            :filteredMessages="filteredMessages" @filteredMessagesChanged="updateFilteredMessages" />
        <loader class="loader" v-if="$donatrix.isPendingDonate" />
    </div>
</template>
<script>
import Periods from '@/components/lastmessages/periods.vue';
import DayFilter from '@/components/lastmessages/dayFilter.vue';
import WeekFilter from '@/components/lastmessages/weekFilter.vue';
import MonthFilter from '@/components/lastmessages/monthFilter.vue';
import SearchResult from '@/components/lastmessages/searchResult.vue';
import NewMessage from '@/components/lastmessages/newMessage.vue';
import Table from '@/components/lastmessages/table.vue';
import CustomInterval from '@/components/lastmessages/customInterval.vue';
import loader from '@/components/dashboard/loader.vue';
import i18n from '../../i18n';


export default {
    components: { Periods, DayFilter, WeekFilter, MonthFilter, SearchResult, NewMessage, Table, CustomInterval, loader },

    data: () => ({
        currentDate: '',
        search: '',
        openPeriod: false,
        choosenPeriod: i18n.t('lastMessages.last'),
        choosenPeriodId: 4,
        newMessageIsOpen: false,
        filteredMessages: [],
        placeholder: i18n.t('lastMessages.placeholder')
    }),

    mounted() {
        document.addEventListener("click", this.handleOutsideClick);
    },

    watch: {
        openPeriod: function (value) {
            if (value) {
                document.addEventListener('click', event => {
                    if ((!document.querySelector('.period')?.contains(event.target))) {
                        this.openPeriod = false
                    }
                });
            }
        },

    },

    methods: {
        openPeriods() {
            if (!this.openPeriod) {
                this.openPeriod = true
            } else {
                this.openPeriod = false
            }
        },

        newMessage() {
            if (!this.newMessageIsOpen) {
                this.newMessageIsOpen = true
            } else {
                this.newMessageIsOpen = false
            }
        },

        handleOutsideClick(event) {
            if ((!this.$refs.child?.$el?.contains(event.target))
                && !document.querySelector('.messages-top__right')?.contains(event.target)) {
                this.newMessageIsOpen = false;
            }
        },

        updatePeriod(newPeriod) {
            this.choosenPeriod = newPeriod
        },

        updatePeriodId(id) {
            this.choosenPeriodId = id
        },

        updateSearch(clean) {
            this.search = clean
        },

        updateFilteredDate(newDate) {
            this.currentDate = newDate
        },

        updateFilteredMessages(newFilteredMessages) {
            this.filteredMessages = newFilteredMessages;
        },
    },

}
</script>
<style scoped lang="scss">
.main {
    position: relative;
}

.loader {
    left: 45%;
}
.messages-title {
    margin-left: 24px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.messages-top {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
}

.messages-top__left {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.messages-top__left-item {
    display: flex;
    position: relative;
    align-items: center;
}

.messages-top__left-item:not(:last-child) {
    margin-right: 24px;
}

.search {
    width: 200px;
    height: 34px;
    padding-left: 21px;
    padding-right: 45px;
    border-radius: 16px;
    border-color: transparent;
    background: rgba(13, 11, 23, 0.6);
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}


.search-icon {
    position: absolute;
    top: auto;
    right: 27px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M10.3333 10.3333L15 15M6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889Z' stroke='%23A2A2A2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.period {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 35px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
    cursor: pointer;
}

.arrow {
    width: 14px;
    height: 7px;
    margin-left: 10px;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='8' viewBox='0 0 15 8' fill='none'%3E%3Cpath d='M14.5 0.5L7.5 7.5L0.5 0.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.messages-top__right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 37px;
    text-transform: uppercase;
}

.button-icon {
    width: 14px;
    height: 14px;
    margin-right: 14px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.5208 6.41667H7.29167V2.1875C7.29167 1.94588 7.09579 1.75 6.85417 1.75C6.61254 1.75 6.41667 1.94588 6.41667 2.1875V6.41667H2.1875C1.94588 6.41667 1.75 6.61254 1.75 6.85417C1.75 7.09579 1.94588 7.29167 2.1875 7.29167H6.41667V11.5208C6.41667 11.7625 6.61254 11.9583 6.85417 11.9583C7.09579 11.9583 7.29167 11.7625 7.29167 11.5208V7.29167H11.5208C11.7625 7.29167 11.9583 7.09579 11.9583 6.85417C11.9583 6.61254 11.7625 6.41667 11.5208 6.41667Z' fill='white'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {
    .messages-title {
        font-size: 18px;
    }

    .search {
        width: 245px;
        height: 37px;
    }

    .period {
        width: 170px;
        height: 40px;
    }

    .messages-top__right {
        width: 223px;
        height: 44px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .messages-title {
        margin-left: 31px;
        margin-top: 12px;
        font-size: 14px;
    }

    .messages-top {
        flex-direction: column;
    }

    .messages-top__left {
        justify-content: flex-start;
    }

    .messages-top__right {
        margin-top: 24px;
        width: 100%;
    }
}

@media (max-width: 680px) {
    .messages-title {
        margin-top: -6px;
        margin-left: 12px;
    }

    .messages-top {
        margin-top: 17px;
    }

    .messages-top__left {
        flex-direction: column;
        max-width: 300px;
    }

    .messages-top__left-item {
        margin-right: 0;
    }

    .search {
        width: 227px;
        margin-bottom: 14px;
    }

    .period {
        height: 37px;
        margin-bottom: 14px;
    }

    .search-icon {
        top: 13px;
    }

    .messages-top__right {
        margin-top: 14px;
        margin-bottom: 2px;
        height: 41px;
    }

}
</style>