<template>
    <span class="filter" >
        <i class="filter-arrow prev" @click="goToPreviousDay"></i>
        {{ formattedDate }}
        <i class="filter-arrow next" @click="goToNextDay"></i>
    </span>
</template>
<script>
import i18n from '../../i18n';

export default {
    data: () => ({
        currentDate: new Date(),
    }),

    mounted() {
        this.$emit('filteredDateChanged', this.formattedDate)
    },
    
    watch: {
        formattedDate(newDate) {
            this.$emit('filteredDateChanged', newDate)
        }
    },

    computed: {
        formattedDate() {
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return this.currentDate.toLocaleDateString(i18n.t('lastMessages.dateFormat'), options).toUpperCase();
        },
    },

    methods: {
        goToPreviousDay() {
            const previousDate = new Date(this.currentDate.getTime() - (24 * 60 * 60 * 1000));
            if (this.shouldNavigateBack(previousDate)) {
                this.currentDate = previousDate;
            }
        },
        goToNextDay() {
            const nextDate = new Date(this.currentDate.getTime() + (24 * 60 * 60 * 1000));
            if (this.shouldNavigateForward(nextDate)) {
                this.currentDate = nextDate;
            }
        },
        shouldNavigateBack(date) {
            const minAllowedDate = new Date(new Date().setMonth(new Date().getMonth() - 11));
            return date >= minAllowedDate && date <= this.currentDate;
        },
        shouldNavigateForward(date) {
            const today = new Date();
            return date <= today && date >= this.currentDate;
        },
    }
}
</script>
<style scoped lang="scss">
.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 146px;
    height: 36px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 41px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
}

.filter-arrow {
    width: 10px;
    height: 11px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.prev {
    margin-left: 21px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7 11L1 6L7 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.next {
    margin-right: 21px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M1 1L6 6L1 11' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px)  {
    .filter {
        height: 41px;
    }
}

@media (max-width: 680px) {
    .filter {
        width: 170px;
        height: 37px;
    }
}
</style>